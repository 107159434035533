<template>
    <div>
        <div class="login_index">
            <div class="logo">
                <van-image width="160" height="160" fit="cover" center src="./logo.png" />
            </div>
            <div class="btn">
                <van-button type="primary" block @click="handleLogin" style="font-size: 16px;">微信一键授权登录</van-button>
            </div>
        </div>
        <div class="icp_box">
            <div class="icp">
                <p>Copyright © 会宁县亿源养殖有限公司</p>
                <p><a href="http://beian.miit.gov.cn/" target="_blank">陇ICP备2020003878号-1</a></p>
                <p><a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=62042202000170"
                        style="color: #333333;display:inline-block;text-decoration:none;height:20px;line-height:20px;">
                        <img src="https://www.yiyuanruye.com/img/icp.png" style="float:left;">甘公网安备 62042202000170号</a>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import { ref, onBeforeMount } from 'vue';
import request from '../../utils/request';
import { useRouter } from "vue-router";
import { showNotify } from 'vant';
export default {
    setup() {
        const code = ref('');
        const router = useRouter();
        const handleLogin = () => {
            request({
                url: '/app/wechat_login',
                method: 'GET'
            }).then((res) => {
                if (res) {
                    var url = res.login_url
                    if (!url) {
                        showNotify('系统错误，授权失败，请关闭系统重新进入')
                        return false
                    } else {
                        // 拼接请求链接
                        var localUrl = window.location.href;
                        url +=
                            "&redirect_uri=" + encodeURIComponent(localUrl) + "#wechat_redirect";
                        window.location.href = url;
                    }
                }
            })
        };
        const requestLogin = () => {
            var requestData = {
                code: code.value,
            };
            request({
                url: '/app/wechat_auth',
                method: 'POST',
                data: { data: requestData }
            }).then((res) => {
                if (res) {
                    localStorage.setItem("systemToken", res.user.access_token);
                    localStorage.setItem("systemTokenExpires", res.user.expires_in);
                    localStorage.setItem("userInfo", JSON.stringify(res.user.user_info));
                    // 跳转首页
                    router.push({ name: "Home" });
                }
            })
        }
        onBeforeMount(() => {
            let queryParam = new URLSearchParams((window.location.href).split('?')[1]);
            if (queryParam.get('code')) {
                code.value = queryParam.get('code')
                requestLogin()
            }
        });
        return {
            handleLogin,
        }
    },
}
</script>
<style>
.login_index {
    text-align: center;
    padding: 25px;
}

.login_index .logo {
    margin-top: 25%;
    margin-bottom: 10%;
}
</style>